<script>
export default [
    {
        text:"Taux",
        value:"taux",
    },
    {
        text:"Quantité",
        value:"quantité",
        selected:true

    },
]
</script>